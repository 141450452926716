<template>
  <modal
    name="flycal"
    transition="pop-out"
    :adaptive="true"
    @closed="resetData"
    @opened="opened"
  >
    <div class="flyCallPopup" id="FlyCallPopup">
      <div class="month_wrapper">
        <vue-scroll :ops="ops">
          <button
            v-for="month of monthsFull"
            :key="month.id"
            class="btn_months"
            :class="[{ btn_months_active: activeMonth.id === month.id }]"
            @click="setMonthsToCalendar(month)"
            :hidden="!selectedYear.availableMonths.includes(month.id)"
          >
            <span>{{ month.label }}</span>
          </button>
        </vue-scroll>
      </div>
      <div class="calendar_wrapper">
        <div class="type-trip">
            <button
              :class="[{ 'trip-button__active': type_trip === 'one_way' }]"
              @click="onChangeTripType('one_way')" class="trip-button button__first">
              <span class="text" v-if="!dMobile">
                <SvgIcon name="plane"/>
            </span><span class="text">
                One-Way
            </span>
            </button>
            <button
              :class="[{ 'trip-button__active': type_trip === 'round_trip' }]"
              @click="onChangeTripType('round_trip')" class="trip-button button__second">
              <span class="text" v-if="!dMobile">
                <SvgIcon name="two_planes"/>
              </span><span class="text">
                Round-Trip
              </span>
            </button>
          </div>
        <div class="year-trip" style="border-top-left-radius: 0; border-top-right-radius: 0">
        <div class="years-container">
          <button
            class="trip-button"
            v-for="(year, index) of optionYears"
            :key="year.year"
            :value="year"
            :class="[{ 'trip-button__active': selectedYear.year === year.year }]"
            @click="selectYear(optionYears[index])"
            ><span class="text">{{ year.year }}</span>
          </button>
        </div>
      </div>
        <v-date-picker v-if="isOneDay"
          class="picker_date"
          color="green"
          is-expanded
          v-model="singleDate"
          is-dark
          locale="en"
          ref="calendar"
          :min-date="selectedYear.min_date"
          :max-date="selectedYear.max_date"
          @update:from-page="changeDatePicker"
        />
        <v-date-picker v-else
          class="picker_date"
          color="green"
          is-expanded
          is-dark
          is-range
          v-model="rangeDate"
          locale="en"
          ref="calendar"
          :min-date="selectedYear.min_date"
          :max-date="selectedYear.max_date"
          @update:from-page="changeDatePicker"
        />
      </div>
      <div class="flycall_form_wrapper">
          <div class="flycall_form">
            <p v-if="isOneDay" class="chosen_var">
              {{ moment(singleDate).format("dddd, MMMM D, YYYY") }}
            </p>
            <p v-else class="chosen_var">
              {{ moment(rangeDate.start).format("dddd, MMMM D, YYYY") }} -
              {{ moment(rangeDate.end).format("dddd, MMMM D, YYYY") }}
            </p>
            <div class="flyCall_twoItems">
              <div class="airports_wrapper">
                <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">
                  Potential departure airports and locations
                </p>
                <div
                  class="airports_line"
                  v-for="(departure, index) in departures"
                  :key="departure.listId"
                >
                  <div class="airports_line-input">
                    <AirportsInput
                      :defaultValue="departure.name"
                      @setValue="
                        (value) =>
                          setAirport(value, departure.listId, 'departure', index)
                      "
                    />
                    <span
                      v-if="!departure.name && isNotReadyToSubmit"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                  This field is required
                </span>
                  </div>
                  <div
                    v-if="
                      departures.length === index + 1 && departures.length < 3
                    "
                    class="button_plus_wrapper"
                  >
                    <button
                      type="button"
                      @click="addAirportToList('departure')"
                      class="plus_button"
                    >
                      <SvgIcon name="plus4" />
                    </button>
                  </div>
                  <div class="button_plus_wrapper">
                    <button
                      @click="(e) => removeAirport(departure.listId, 'departure', e.currentTarget)"
                      class="plus_button del_button"
                    >
                      <SvgIcon name="plus4" />
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="input_wrapper">
                <p class="inputs_label">Departure Re-Routing<br/> Radius</p>
                <input
                  class="field"
                  type="number"
                  v-model="departureRadius1"
                  placeholder="Enter nautical miles"
                  autocomplete="off"
                />
              </div> -->
            </div>
            <div class="flyCall_twoItems">
              <div class="airports_wrapper">
                <p class="inputs_label" style="font-size: 0.875rem; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">
                  Potential arrival airports and locations
                </p>
                <div
                  class="airports_line"
                  v-for="(landing, index) in landings"
                  :key="landing.listId"
                >
                  <div class="airports_line-input">
                    <AirportsInput
                      :defaultValue="landing.name"
                      @setValue="
                        (value) => setAirport(value, landing.listId, 'landing')
                      "
                    />
                    <span
                      v-if="!landing.name && isNotReadyToSubmit"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                  This field is required
                </span>
                  </div>
                  <div
                    v-if="landings.length === index + 1 && landings.length < 3"
                    class="button_plus_wrapper"
                  >
                    <button
                      type="button"
                      @click="addAirportToList('landing')"
                      class="plus_button"
                    >
                      <SvgIcon name="plus4" />
                    </button>
                  </div>
                  <div class="button_plus_wrapper">
                    <button
                      @click="(e) => removeAirport(landing.listId, 'landing', e.currentTarget)"
                      class="plus_button del_button"
                    >
                      <SvgIcon name="plus4" />
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="input_wrapper">
                <p class="inputs_label">Arrival Re-Routing<br/> Radius</p>
                <input
                  class="field"
                  type="number"
                  v-model="departureRadius2"
                  placeholder="Enter nautical miles"
                  autocomplete="off"
                />
              </div> -->
            </div>
            <p class="form_subtitle">
              CHARTER TYPE (select only one option)
            </p>
            <div class="charter_type_wrapper">
              <button
                class="buttonBlackOutline"
                type="button"
                :class="[
                  { buttonBlackOutline__active: charterType === 1 },
                ]"
                @click="setCharterType(1)"
              >
                <span class="text">Charter aircraft</span>
              </button>
              <button
                class="buttonBlackOutline"
                type="button"
                :class="[
                  { buttonBlackOutline__active: charterType === 2 },
                ]"
                @click="setCharterType(2)"
              >
                <span class="text">Charter space</span>
              </button>
            </div>
            <div class="flyCall_twoItems" style="margin-bottom: 45px">
              <div class="input_wrapper input_wrapper_two">
                <p class="inputs_label">Number of spaces</p>
                <input
                  class="field"
                  type="number"
                  v-model="numberOfSpaces"
                  placeholder="Enter number of spaces"
                  autocomplete="off"
                />
              </div>
              <div class="input_wrapper input_wrapper_two">
                <p class="inputs_label">Budget goal</p>
                <input
                  class="field"
                  type="number"
                  v-model="budgetGoal"
                  placeholder="Enter $ amount"
                  autocomplete="off"
                />
              </div>
            </div>
            <button
              @click="submitHandler()"
              class="launch-btn"
            >
              <span class="text">Launch</span>
            </button>
          </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('flycal')">
        <SvgIcon name="close-white-2"/>
      </button>
    </div>
  </modal>
</template>

<script>
import moment from 'moment';
import './FlyCallPopup.styl';
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import AirportsInput from '@/views/dashboard/provider/AutomatedScheduler/AirportsInput/AirportsInput';
import { monthsFull } from '@/js/utils/date';

export default {
  name: 'FlyCallPopup',
  data: () => ({
    departures: [
      {
        name: '',
        id: null,
        listId: 0,
      },
    ],
    landings: [
      {
        name: '',
        id: null,
        listId: 0,
      },
    ],
    type_trip: 'one_way',
    departureRadius1: 0,
    departureRadius2: 0,
    numberOfSpaces: '',
    budgetGoal: '',
    monthsFull,
    activeMonth: {},
    charterType: 1,
    optionYears: [],
    selectedYear: {},
    rangeDate: {
      start: '',
      end: '',
    },
    singleDate: new Date(),
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    isNotReadyToSubmit: false,
    dMobile: undefined,
  }),
  computed: {
    isRangeDate () {
      return this.rangeDate !== null;
    },
    isOneDay () {
      return this.singleDate !== null;
    },
  },
  async created () {
    // prepare `optionYears` and `selectedYear` ------------------------------
    const d = new Date()
    const currentDayNum = d.getDate()
    const currentMonthNum = d.getMonth()
    const currentFullYear = d.getFullYear()

    const currentYearMonths = []
    for (let i = currentMonthNum; i <= 11; i++) {
      currentYearMonths.push(i)
    }

    const nextYearMonths = []
    for (let i = 0; i <= currentMonthNum; i++) {
      nextYearMonths.push(i)
    }

    this.optionYears = [
      {
        availableMonths: currentYearMonths,
        year: currentFullYear,
        min_date: new Date(currentFullYear, currentMonthNum, currentDayNum),
        max_date: new Date(currentFullYear + 1, 11, 31),
      },
      {
        availableMonths: nextYearMonths,
        year: currentFullYear + 1,
        min_date: new Date(currentFullYear + 1, 0, 1),
        max_date: new Date(currentFullYear + 1, currentMonthNum, currentDayNum),
      },
    ]

    this.selectedYear = {
      availableMonths: currentYearMonths,
      year: currentFullYear,
      min_date: new Date(currentFullYear, currentMonthNum, currentDayNum),
      max_date: new Date(currentFullYear + 1, 11, 31),
    }
    // -----------------------------------------------------------------------
  },
  async mounted () {
    this.activeMonth = this.monthsFull[this.selectedYear.availableMonths[0]];
    this.dMobile = document.querySelector('html').classList.contains('d-mobile');
  },
  methods: {
    isReadyToSubmit () {
      return (this.singleDate || this.rangeDate.start) &&
        this.charterType &&
        (this.departures.length > 0 && this.departures[0].id) &&
        (this.landings.length > 0 && this.landings[0].id) &&
        /^[0-9]+$/.test(this.numberOfSpaces) &&
        /^[0-9]+$/.test(this.budgetGoal);
    },
    submitHandler () {
      // console.log()

      if (!this.isReadyToSubmit()) {
        console.log('Invalid fields.')
        this.isNotReadyToSubmit = true

        return false
      }

      this.isNotReadyToSubmit = false

      const flyCalPayload = {
        direction: this.isOneDay ? 2 : 1,
        bookingCategory: this.charterType === 1 ? 'aircraft' : 'spaces',
        firstDepartureId: this.departures[0].id,
        secondDepartureId: this.departures.length > 1 && this.departures[1].id
          ? this.departures[1].id
          : null,
        thirdDepartureId: this.departures.length > 2 && this.departures[2].id
          ? this.departures[2].id
          : null,
        firstArrivalId: this.landings[0].id,
        secondArrivalId: this.landings.length > 1 && this.landings[1].id
          ? this.landings[1].id
          : null,
        thirdArrivalId: this.landings.length > 1 && this.landings[1].id
          ? this.landings[1].id
          : null,
        departureDate: this.isOneDay ? this.singleDate.toLocaleDateString('en-US') : this.rangeDate.start.toLocaleDateString('en-US'),
        returnDate: this.isOneDay ? null : this.rangeDate.end.toLocaleDateString('en-US'),
        passengersNumber: Number(this.numberOfSpaces),
        budgetGoal: Number(this.budgetGoal),
        // departureReroutingRadius: Number(this.departureRadius1),
        // arrivalReroutingRadius: Number(this.departureRadius2),
        isMobileRequest: false,
      }

      // console.log(flyCalPayload)
      this.$emit('payload', flyCalPayload)

      // ---------------------------------------------------------------------

      // const reqData = {
      //   direction: (this.rangeDate !== null &&
      //     new Date(this.rangeDate.start).getTime() ===
      //     new Date(this.rangeDate.end).getTime()) ? 2 : 1,
      //   bookingCategory: this.charterType === 1 ? 'aircraft' : 'spaces',
      //   firstDepartureId: this.departures[0].id,
      //   secondDepartureId: this.departures[1] ? this.departures[1].id : null,
      //   thirdDepartureId: this.departures[2] ? this.departures[2].id : null,
      //   firstArrivalId: this.landings[0].id,
      //   secondArrivalId: this.landings[1] ? this.landings[1].id : null,
      //   thirdArrivalId: this.landings[2] ? this.landings[2].id : null,
      //   departureDate: this.rangeDate.start ? this.rangeDate.start : this.rangeDate,
      //   returnDate: this.rangeDate.end ? this.rangeDate.end : null,
      //   passengersNumber: this.numberOfSpaces,
      //   budgetGoal: this.budgetGoal,
      //   departureReroutingRadius: this.departureRadius1,
      //   arrivalReroutingRadius: this.departureRadius2,
      //   notes: '',
      //   isMobileRequest: false
      // };

      // this.$store.commit('setFlycalPopupState', reqData)
      // this.$modal.hide('FlyCallPopup')
      // this.$modal.show('LoginOrSignupPopup')
    },
    moment,
    setCharterType (type) {
      this.charterType = type;
    },
    async setMonthsToCalendar (month) {
      this.activeMonth = month;
      await setTimeout(() => {
        this.$refs.calendar.move({ month: month.id + 1, year: this.selectedYear.year });
      }, 0);
    },
    async selectYear (value) {
      const yearObj = this.optionYears.filter(y => y.year === value.year)[0];
      this.activeMonth = this.monthsFull[yearObj.availableMonths[0]];
      this.selectedYear = yearObj;

      await setTimeout(() => {
        this.$refs.calendar.move(yearObj.min_date);
      }, 0);
    },
    setAirport (value, id, type, index) {
      if (type === 'departure') {
        this.departures = this.departures.map((departure) => {
          if (departure.listId === id) {
            return {
              id: value.id,
              listId: id,
              name: value.name,
            };
          } else return departure;
        });
      } else {
        this.landings = this.landings.map((landing) => {
          if (landing.listId === id) {
            return {
              id: value.id,
              name: value.name,
              listId: id,
            };
          } else return landing;
        });
      }
    },
    addAirportToList (type) {
      if (type === 'departure') {
        this.departures = [
          ...this.departures,
          { name: '', id: null, listId: this.departures.length + 1 },
        ];
      } else {
        this.landings = [
          ...this.landings,
          { name: '', id: null, listId: this.landings.length + 1 },
        ];
      }
    },
    removeAirport (id, type, e) {
      if (type === 'departure') {
        if (this.departures.filter(({listId}) => listId === id)[0].name.length > 0) {
          this.departures = this.departures.map((departure) => {
            if (departure.listId === id) {
              return ({listId: id, id: null, name: ''})
            } else return departure;
          });
          e.closest('.airports_line').querySelector('input').value = ''
          return
        }
        if (this.departures.filter(({listId}) => listId === id)[0].name.length === 0 && this.departures.length > 1) {
          this.departures = this.departures.filter(({listId}) => listId !== id);
        }
      } else {
        if (this.landings.filter(({listId}) => listId === id)[0].name.length > 0) {
          this.landings = this.landings.map((landings) => {
            if (landings.listId === id) {
              return ({listId: id, id: null, name: ''})
            } else return landings;
          });
          e.closest('.airports_line').querySelector('input').value = ''
          return
        }
        if (this.landings.filter(({listId}) => listId === id)[0].name.length === 0 && this.landings.length > 1) {
          this.landings = this.landings.filter(({listId}) => listId !== id);
        }
      }
    },
    async opened () {
      this.activeMonth = this.monthsFull[this.selectedYear.availableMonths[0]];
      await setTimeout(() => {
        this.$refs.calendar.move({ month: 1, year: this.selectedYear });
      }, 100)
    },
    resetData () {
      this.departures = [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ];
      this.landings = [
        {
          name: '',
          id: null,
          listId: 0,
        },
      ];
      this.departureRadius1 = 0;
      this.departureRadius2 = 0;
    },
    onChangeTripType (tripType) {
      this.type_trip = tripType;
      if (this.type_trip === 'one_way') {
        if (this.rangeDate.start) {
          this.singleDate = this.rangeDate.start;
        } else {
          this.singleDate = new Date();
        }
      } else {
        this.singleDate = null;
      }
      this.rangeDate = {
          start: '',
          end: '',
      };
    },
    changeDatePicker (value) {
        if (this.selectedYear.year !== value.year) {
          this.selectYear(value);
        }
    },
  },
  watch: {
    singleDate () {
      if (this.singleDate === null && this.type_trip === 'one_way') {
        this.onChangeTripType('one_way')
      }
    },
  },
  components: {
    AirportsInput,
    SvgIcon,
  },
};
</script>
