<template>
  <form
    class="form form--resetPassword js-changePassword js-ajaxForm"
    :class="{'loading' : loading}"
    data-action="resetPassword"
    id="resetPasswordForm"
    @submit.prevent="submitHandler"
  >
    <label
      class="form__label form__label--text"
      :class="{'invalid' : !$v.email.required || !$v.email.email}">
      <div class="form__caption">Email</div>
      <div class="form__field-wrapper">
        <input
          class="form__field"
          v-model.trim="email"
          autocomplete="off"
          placeholder="Email address"
        />
        <div
          v-if="$v.email.$dirty && !$v.email.required"
          class="form__error">
          This field is required
        </div>
        <div
          v-if="$v.email.$dirty && !$v.email.email"
          class="form__error">
          Enter a valid Email
        </div>
      </div>
    </label>
    <label
      class="form__label form__label--password"
      :class="{'invalid' : !$v.newPassword.required || !$v.newPassword.minLength}">
      <div class="form__caption">New password</div>
      <div class="form__field-wrapper">
        <input
          class="form__field"
          :type="switchedIcons.newPassword.type"
          v-model.trim="newPassword"
          autocomplete="off"
          placeholder="Enter new password"
        />
        <div
          class="form__icon js-openPass"
          :class="{'openPassword' : switchedIcons.newPassword.status}"
          @click="changeVisible('newPassword')"
        >
          <span class="form__icon-open"><SvgIcon name="eye-open"/></span>
          <span class="form__icon-close"><SvgIcon name="eye-close"/></span>
        </div>
        <div
          v-if="$v.newPassword.$dirty && !$v.newPassword.required"
          class="form__error">
          This field is required
        </div>
        <div v-if="!$v.newPassword.minLength" class="form__error">min length {{$v.newPassword.$params.minLength.min}}
          symbols
        </div>
      </div>
    </label>
    <label
      class="form__label form__label--confirm"
      :class="[
        {'invalid' : confirmInvalid},
        {'disabled' : !$v.newPassword.required || !$v.newPassword.minLength}
      ]"
    >
      <div class="form__caption">Confirm password</div>
      <div class="form__field-wrapper">
        <input
          class="form__field"
          :type="switchedIcons.confirmPassword.type"
          :disabled="!$v.newPassword.required || !$v.newPassword.minLength"
          v-model.trim="confirmPassword"
          autocomplete="off"
          placeholder="Confirm new password"
          @input="confirmSamePassword"
        />
        <div
          class="form__icon js-openPass"
          :class="{'openPassword' : switchedIcons.confirmPassword.status}"
          @click="changeVisible('confirmPassword')"
        >
          <span class="form__icon-open"><SvgIcon name="eye-open"/></span>
          <span class="form__icon-close"><SvgIcon name="eye-close"/></span>
        </div>
        <div v-if="confirmInvalid" class="form__error">passwords do not match</div>
      </div>
    </label>
    <div class="form__label form__label--button">
      <Spinner/>
      <button class="form__button" :disabled="!displayButton">
        <span class="text">Change password</span>
      </button>
    </div>
  </form>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { email, required, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'ResetPassword',
    data: () => ({
      email: '',
      newPassword: '',
      confirmPassword: '',
      confirmInvalid: false,
      displayButton: false,
      switchedIcons: {
        newPassword: {
          status: false,
          type: 'password',
        },
        confirmPassword: {
          status: false,
          type: 'password',
        },
      },
      loading: false,
    }),
    validations: {
      email: {
        email,
        required,
      },
      newPassword: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
      },
    },
    methods: {
      changeVisible (elem) {
        for (const key in this.switchedIcons) {
          if (elem === key) {
            this.switchedIcons[elem].status = !this.switchedIcons[elem].status
            this.switchedIcons[elem].type === 'password' ? this.switchedIcons[elem].type = 'text'
              : this.switchedIcons[elem].type = 'password'
          }
        }
      },
      confirmSamePassword () {
        if (this.confirmPassword === this.newPassword) {
          this.confirmInvalid = false
          this.displayButton = true
        } else {
          this.confirmInvalid = true
          this.displayButton = false
        }
      },
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          email: this.email,
          password: this.newPassword,
          token: this.$route.params.id,
        }

        this.loading = true
        this.displayButton = false

        const result = await this.$store.dispatch('resetPassword', data)
        if (result) {
          this.$router.push('/')
        }

        this.loading = false
        this.displayButton = true
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
