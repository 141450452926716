<template>
  <modal
    name="flycal-signup"
    transition="pop-out"
    @before-open="beforeOpen"
    @opened="clickCountryCode"
    @closed="$v.$reset()"
  >
    <div class="formPopup formPopup--signUp flyCallSignUpPopup" id="signUpPopup">
      <div class="formPopup__inner">
        <div class="formPopup__left">
          <div class="formPopup__decor"></div>
        </div>
        <div class="formPopup__right js-tabBox">
          <div style="justify-content:center;" class="formPopup__header">
            <div class="formPopup__title-wr">
              <div class="flyCalendar-popup__back" @click="back">
                <SvgIcon name="left-arrow"/>
              </div>
              <div class="formPopup__title">flyer Sign up</div>
            </div>
          </div>
          <div class="formPopup__tabContent">
            <div class="formPopup__tabContent-item js-tabSignContent active" data-id="flyer">
              <form
                class="form form--signUpForm js-ajaxForm"
                :class="{'loading' : loading}"
                data-href="#"
                data-action="signUpFlyer"
                id="signUpFlyerForm"
                @submit.prevent="submitHandlerFlyer">
                <Spinner/>
                <div class="form__labelGroup">
                  <label class="form__label form__label--text">
                    <div class="form__caption">First Name</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="firstname"
                        autocomplete="off"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.firstname.$dirty && !$v.firstname.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Middle Name</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="middlename"
                        autocomplete="off"
                        :disabled="loading"
                      />
                    </div>
<!--                    <span-->
<!--                      v-if="$v.middlename.$dirty && !$v.middlename.required"-->
<!--                      class="form__field-invalid form__field-invalid&#45;&#45;right-side"-->
<!--                    >-->
<!--                      This field is required-->
<!--                    </span>-->
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Last Name</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="lastname"
                        autocomplete="off"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.lastname.$dirty && !$v.lastname.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Email</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="email"
                        v-model="email"
                        autocomplete="off"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.email.$dirty && !$v.email.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.email.$dirty && !$v.email.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="form__label form__label--text phone">
                    <div class="form__caption">Phone</div>
                    <div class="form__field-wrapper">
                      <VuePhoneNumberInput v-model="phone" clearable @update="updatePhone" :dark="true" :disabled="loading" default-country-code="US" />
<!--                      <input-->
<!--                        class="form__field"-->
<!--                        type="text"-->
<!--                        v-model="phone"-->
<!--                        autocomplete="off"-->
<!--                        v-mask="'+1 (###) ###-##-##'"-->
<!--                        placeholder="+1 (___) ___-__-__"-->
<!--                        :disabled="loading"-->
<!--                      />-->
                    </div>
                    <span
                      v-if="$v.phone.$dirty && !$v.phone.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="password"
                        autocomplete="new-password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.password.$dirty && !$v.password.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Confirm password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="confirmPassword"
                        autocomplete="new-password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                </div>
                <div style="margin-bottom:0;" class="form__label form__label--button">
                  <button
                    class="form__button form__button"
                    :disabled="loading"
                  >
                    <span class="text">Complete</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('flycal-signup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import { usersAPI } from '@/api/accounts'
  import Spinner from '@/components/common/Spinner/Spinner'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Tabs } from '@/js/plugins/tabs'
  import { email, required } from 'vuelidate/lib/validators'
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import './FlyCallSignUpPopup.styl'

  export default {
    name: 'FlyCalSignUpPopup',
    data: () => ({
      tabs: new Tabs('.js-tabSignItem', '.js-tabSignContent'),
      firstname: '',
      middlename: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      loading: false,
      formattedPhone: '',
    }),
    validations: {
      firstname: { required },
      lastname: { required },
      phone: { required },
      email: {
        email,
        required,
      },
      password: { required },
      confirmPassword: { required },
    },
    methods: {
      beforeOpen () {
        setTimeout(() => {
          this.tabs.addActiveForMouted()
          document.querySelector('.country-selector__list').style.opacity = 0
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
        }, 0)
      },
      updatePhone (payload) {
        this.formattedPhone = payload.formattedNumber
      },
      clickCountryCode () {
        setTimeout(() => {
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
        }, 0)
        setTimeout(() => {
          document.querySelector('.country-selector__list').style.opacity = 1
        }, 1000)
      },
      back () {
        this.$modal.hide('flycal-signup')
        this.$modal.show('login-or-signup')
      },
      tabActivate (event) {
        // eslint-disable-next-line no-return-assign
        document.querySelectorAll('.country-selector__list').forEach(list => list.style.opacity = 0)
        this.tabs.addActiveForClick(event.target)
        this.firstname = ''
        this.middlename = ''
        this.lastname = ''
        this.phone = ''
        this.email = ''
        this.password = ''
        this.confirmPassword = ''
        this.$v.$reset()
        setTimeout(() => {
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
        }, 0)
        setTimeout(() => {
          // eslint-disable-next-line no-return-assign
          document.querySelectorAll('.country-selector__list').forEach(list => list.style.opacity = 1)
        }, 1000)
      },
      async submitHandlerFlyer () {
        if (this.$v.firstname.$invalid ||
          this.$v.phone.$invalid ||
          this.$v.email.$invalid ||
          this.$v.password.$invalid ||
          this.$v.confirmPassword.$invalid ||
          this.password !== this.confirmPassword
        ) {
          this.$v.$touch()
          return false
        }

        this.loading = true

        const data = {
          accountType: 1,
          firstName: this.firstname,
          middleName: this.middlename,
          lastName: this.lastname,
          companyName: 'N/A',
          mobile: this.formattedPhone,
          email: this.email,
          password: this.password,
          timeZone: 1,
          isFlyCalSignUp: true,
        }

        try {
          const response = await usersAPI.signUp(data);

          console.log('before otp')
          console.log(response);
          if (response.data.isSuccessfull) {
            this.$emit('otp', { id: response.data.item.id, email: response.data.item.email, password: this.password })
          } else {
            this.$emit('error', 'otp')
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
          this.firstname = ''
          this.middlename = ''
          this.lastname = ''
          this.phone = ''
          this.email = ''
          this.password = ''
          this.confirmPassword = ''
          this.formattedPhone = ''
        }

        // -------------------------------------------------------------------

        // if (this.$v.firstname.$invalid ||
        //   this.$v.phone.$invalid ||
        //   this.$v.email.$invalid ||
        //   this.$v.password.$invalid ||
        //   this.$v.confirmPassword.$invalid ||
        //   this.password !== this.confirmPassword
        // ) {
        //   this.$v.$touch()
        //   return false
        // }

        // const data = {
        //   accountType: 1,
        //   firstName: this.firstname,
        //   middleName: this.middlename,
        //   lastName: this.lastname,
        //   companyName: 'N/A',
        //   mobile: this.phone,
        //   email: this.email,
        //   password: this.password,
        //   timeZone: 1
        // }

        // this.loading = true

        // const result = await this.$store.dispatch('flyCalSignUp', data)
        // if (result) {
        //   console.log('sign up: true');
        //   this.$modal.hide('FlyCalSignUpPopup')
        //   this.$modal.show('FrontTransactionPopup')
        // }

        // this.loading = false
      },
      // async submitHandlerProvider () {
      //   if (this.$v.firstnameProvider.$invalid ||
      //     this.$v.companyProvider.$invalid ||
      //     this.$v.phoneProvider.$invalid ||
      //     this.$v.emailProvider.$invalid ||
      //     this.$v.passwordProvider.$invalid ||
      //     this.$v.confirmPasswordProvider.$invalid ||
      //     this.passwordProvider !== this.confirmPasswordProvider) {
      //     this.$v.$touch()
      //     return false
      //   }

      //   const data = {
      //     accountType: 2,
      //     firstName: this.firstnameProvider,
      //     middleName: this.middlenameProvider,
      //     lastName: this.lastnameProvider,
      //     companyName: this.companyProvider,
      //     mobile: this.phoneProvider,
      //     email: this.emailProvider,
      //     password: this.passwordProvider
      //   }

      //   this.loading = true

      //   const result = await this.$store.dispatch('signUp', data)
      //   console.log(result);

      //   if (result) {
      //     console.log('sign up: true', result);
      //     this.$modal.hide('SignUpPopup')
      //     const message = {
      //       title: 'Thanks for registration!',
      //       text: 'We will review your request and get back to you.'
      //     }
      //     this.$modal.show('ThanksPopup', {message})
      //   }

      //   this.loading = false
      // }
    },
    components: {
      Spinner,
      SvgIcon,
      VuePhoneNumberInput,
    },
  }
</script>
