<template>
  <modal
    class="white-popup"
    name="login-or-signup"
    transition="pop-out"
    :adaptive="true"
  >
    <div class="comingSoonPopup login-or-signup" id="comingSoonPopup" style="background-color: #37373e;">
     <!-- <div class="comingSoonPopup__decor" style="background-image:url('/img/assets/common/comingSoon/planet.png')"></div> -->
        <div class="comingSoonPopup__inner" style="padding-top:7%;">
            <!-- <div class="comingSoonPopup__icon">
                <img src="@/assets/img/comingSoonMail.png">
            </div> -->
            <div class="comingSoonPopup__title" style="font-size: 24px; margin-bottom:15%; color: #fff;">Log in or sign up to check out</div>
            <div class="comingSoonPopup__text">
          <div class="mainBlock__button-wr" v-if="type !== 'reset'">
            <button
              style="box-shadow:inset 0 0 0 2px #fff;"
              class="mainBlock__button login-or-signup-button login"
              @click="clickLogIn()"
            >
              <span class="text">Log in</span>
            </button>
            <button
              class="mainBlock__button mainBlock__button--active login-or-signup-button"
              @click="clickSignUp()"
            >
              <span class="text">Sign up</span>
            </button>
          </div>
            </div>
        </div>
        <button type="button" class="modal__close" style="left: calc(100% + 40px); top: 0;" @click="$modal.hide('login-or-signup')">
          <SvgIcon name="close-white-2"/>
        </button>
    </div>
  </modal>
</template>
<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './ComingSoonPopup.styl'

  export default {
    name: 'LoginOrSignupPopup',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      type: null,
    }),
      methods: {
        clickSignUp () {
          this.$emit('authType', 'signup')
        },
        clickLogIn () {
          this.$emit('authType', 'login')
        },
      },
      components: {
        SvgIcon,
      },
  }
</script>
