<template>
  <modal
    name="flycal-login"
    transition="pop-out"
    :adaptive="true"
    @closed="$v.$reset()"
  >
    <div class="formPopup flyCallLoginPopup" id="loginPopup">
      <div class="formPopup__inner">
        <div class="formPopup__left" style="width:16%; padding-top:0; padding-bottom:0;">
          <div class="formPopup__decor"></div>
        </div>
        <div class="formPopup__right flycalendar-login">
          <div class="formPopup__header">
            <div class="flyCalendar-popup__back" @click="back">
              <SvgIcon name="left-arrow"/>
            </div>
            <div class="formPopup__title">flyer Log in</div>
          </div>
          <div class="formPopup__form">
            <form
              class="form form--login js-ajaxForm"
              :class="{'loading' : loading}"
              data-href="/json/test.json"
              data-action="login"
              id="loginForm"
              @submit.prevent="submitHandler"
              style="width:100%;"
            >
              <Spinner/>
              <div class="form__fields-wrapper">
                <label class="form__label form__label--text" style="margin-right: 4%">
                  <div class="form__caption">Email</div>
                  <div class="form__field-wrapper">
                    <input
                      class="form__field"
                      type="email"
                      v-model.trim="email"
                      placeholder="Email address"
                      :disabled="loading"
                    />
                  </div>
                  <span
                    v-if="$v.email.$dirty && !$v.email.required"
                    class="form__field-invalid form__field-invalid--right-side"
                  >
                  This field is required
                </span>
                  <span
                    v-if="$v.email.$dirty && !$v.email.email"
                    class="form__field-invalid form__field-invalid--right-side"
                  >
                  Enter a valid Email
                </span>
                </label>
                <label
                  class="form__label form__label--text">
                    <div class="form__caption">Password</div>
                  <span class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="password"
                    v-model.trim="password"
                    placeholder="Enter your password"
                    :disabled="loading"
                  />
                </span>
                  <span
                    v-if="$v.password.$dirty && !$v.password.required"
                    class="form__field-invalid form__field-invalid--right-side"
                  >
                  This field is required
                </span>
<!--                  <a class="form__link form__link&#45;&#45;forgot js-popup" @click="changeToForgot">Forgot password?</a>-->
                </label>
              </div>
              <div class="flyCalendar-popup__forgotWrapper">
                <a class="flyCalendar-popup__forgot js-popup" @click="changeToForgot">FORGOT PASSWORD?</a>
              </div>
              <div
                class="form__label form__label--button">
                <button
                  class="form__button"
                  :disabled="loading"
                >
                  <span class="text">Log in</span>
                </button>
                <!-- <div class="form__link js-popup" @click="changeToSign">Sign up</div> -->
              </div>
            </form>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('flycal-login')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { email, required } from 'vuelidate/lib/validators'
  import './FlyCallLoginPopup.styl'

  export default {
    name: 'FlyCalLogInPopup',
    data: () => ({
      email: '',
      password: '',
      loading: false,
    }),
    validations: {
      email: {
        email,
        required,
      },
      password: { required },
    },
    methods: {
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()

          return false
        }

        this.$emit('credentials', { email: this.email, password: this.password })

        // -------------------------------------------------------------------

        // if (this.$v.$invalid) {
        //   this.$v.$touch()
        //   return false
        // }

        // const data = {
        //   email: this.email,
        //   password: this.password
        // }

        // this.loading = true

        // const result = await this.$store.dispatch('loginUser', data)
        // if (result) {
        //   this.$modal.hide('FlyCalLogInPopup')
        //   this.loading = false
        //   const flyCalStatus = await this.$store.dispatch('checkFlyCalStatus')
        //   if (flyCalStatus) {
        //       this.$store.dispatch('createFlyCal', this.$store.state.flycal.reqData)
        //       this.$modal.show('FlyCalFrontPaymentProcessedPopup')
        //   } else {
        //       this.$modal.show('FrontTransactionPopup')
        //   }
        //   } else {
        //       // show invalid login popup? goto register?
        //   this.loading = false
        // }
      },
      back () {
        this.$modal.hide('flycal-login')
        this.$modal.show('login-or-signup')
      },
      changeToSign () {
        this.$modal.hide('flycal-login')
        this.$modal.show('SignUpPopup')
      },
      changeToForgot () {
        this.$modal.hide('flycal-login')
        this.$modal.show('ForgotPasswordPopup')
      },
    },
    components: {
      Spinner,
      SvgIcon,
    },
  }
</script>
