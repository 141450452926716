<template>
  <modal
    name="one-time-password"
    transition="pop-out"
    @before-open="setEmail"
  >
  <!-- <modal
    name="one-time-password"
    transition="pop-out"
    @before-open=""
    @before-close=""
  > -->
    <div
      id="signUpPopup"
      class="formPopup formPopup--signUp OneSignUpPopup"
    >
      <div class="formPopup__inner">
<!--        <div class="formPopup__left">-->
<!--          <div class="formPopup__decor"></div>-->
<!--        </div>-->
        <div
          class="formPopup__right"
          style="align-items: center; "
        >
          <div class="flyCalendar-popup__title">enter code</div>
          <div style="margin-bottom: 45px; line-height: 20px; text-align: center">
            Please check your email for a 6-digit verification code
          </div>
          <div class="otp__inputs-wrapper" style="flex-direction: row;">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              @on-complete="handleOnComplete"
            />
            <!-- <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            /> -->
          </div>
<!--          <button-->
<!--            class="buttonGreen"-->
<!--            style="margin-top: 24px"-->
<!--            :disabled="loading"-->
<!--            @click="handleClearInput()"-->
<!--          >-->
<!--            <span class="text">Clear</span>-->
<!--          </button>-->
          <button
            class="buttonGreen"
            style="margin-top: 50px"
            :disabled="loading"
            @click="handleVerifyInput()"
          >
            <span class="text">Done</span>
          </button>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('one-time-password')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import { usersAPI } from '@/api/accounts'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './OneTimePasswordPopUp.styl'

  export default {
    name: 'OneTimePasswordPopUp',
    data: () => ({
      flyCalPayload: null,
      otp: null,
      email: null,
      loading: false,
    }),

    methods: {
      setEmail (event) {
        // console.log(event)

        this.email = event.params.email
      },
      // handleOnChange (value) {
      //   console.log('OTP changed: ', value)
      // },
      handleOnComplete (value) {
        this.otp = value
      },
      // handleClearInput () {
      //   this.$refs.otpInput.clearInput()
      // },
      async handleVerifyInput () {
        try {
          this.loading = true

          await usersAPI.verifyWithOTP({ email: this.email, otpNum: Number(this.otp) })

          this.$emit('verified')
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
