<template>
  <div class="mapBlock__form-bottom darkPicker select_wrapper">
    <div class="form__labelGroup">
      <label class="form__label form__label--text" style="margin-bottom: 15px">
        <div class="form__field-wrapper">
          <input
            class="form__field js-typeahead field"
            type="text"
            :value="defaultValue"
            data-type="value"
            @input="getLocationsFrom($event.target.value)"
            @focus="showTypeheadMenu"
            @blur="hideTypeheadMenu"
            placeholder="Choose from list"
            :disabled="disabled === true"
          />
          <div
            class="typeahead-menu typeahead-empty"
            :class="{'typeahead-open' : isOpenMenu}"
            v-if="locationsListFrom.length > 0"
          >
            <div class="typeahead-dataset typeahead-dataset-departureLocations">
              <div
                class="typeahead-suggestion typeahead-selectable"
                data-type="select-from"
                v-for="(item, index) of locationsListFrom" :key="index"
                @click="selectLocation(item)"
              >
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
  import './AirportsInput.styl'
  export default {
    name: 'AirportsInput',
    props: ['placeholder', 'setValue', 'defaultValue', 'disabled'],
    data: () => ({
      value: null,
      isOpenMenu: null,
      timeout: null,
    }),
    computed: {
      locationsListFrom () {
        return this.$store.getters.shortListLocationsFrom(4)
      },
      locationsListTo () {
        return this.$store.getters.shortListLocationsTo(4)
      },
    },
    methods: {
      async getLocationsFrom (value) {
        this.value = value
        const that = this
        if (value.length === 0) {
          this.selectLocation({id: null, name: ''})
        }
        this.$emit('setValue', {id: null, name: this.value})

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null
          const data = {
            query: that.value,
            type: 'from',
          }

          await that.$store.dispatch('searchLocations', data)
          that.isOpenMenu = true;
          if (that.value === '' || !that.locationsListFrom.length) {
            // remove marker
            that.isOpenMenu = false;
          }
        }, 500)
      },
      showTypeheadMenu () {
        this.$store.dispatch('deleteLocations')
        this.isOpenMenu = true;
        this.value = this.$props.defaultValue
      },
      hideTypeheadMenu () {
        const that = this
        setTimeout(function () {
          that.isOpenMenu = false
        }, 200)
      },
      selectLocation (item) {
        const that = this;
        this.value = item.name;
        this.$emit('setValue', {id: item.id, name: item.name});
        setTimeout(function () {
          that.isOpenMenu = false
        }, 200)
      },
    },
  }
</script>
