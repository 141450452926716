<template>
  <div class="box box--mainBlock box--mainBlockSmallPadding">
    <div class="box__decor box__decor--1"></div>
    <div class="box__decor box__decor--2"></div>
    <div class="box__inner">
      <div class="mainBlock"
           :class="[{'mainBlock--reset' : type === 'reset'}]">
        <div class="mainBlock__header">
<!--          <div class="mainBlock__language">-->
<!--            <SelectLang :select="'max'"/>-->
<!--          </div>-->
          <div class="mainBlock__slogan">An aviation marketplace<br>and travel planning system</div>
        </div>

        <div class="mainBlock__content" v-if="type === 'reset'">
          <div class="mainBlock__title">Reset password</div>
          <div class="mainBlock__form">
            <ResetPassword/>
          </div>
        </div>

        <div class="mainBlock__content" v-else>
          <div class="mainBlock__info mainBlock__info--1">
            <div class="mainBlock__info-title">For<br>flyers</div>
            <div class="mainBlock__info-text">Search and book aircraft,<br>flights, hotels and more</div>
          </div>
          <div class="mainBlock__logo js-mainLogo"><img src="@/assets/img/logo.png"/></div>
          <div class="mainBlock__info mainBlock__info--2">
            <div class="mainBlock__info-title">For aircraft providers</div>
            <div class="mainBlock__info-text">Upload aircraft and<br>availability schedules</div>
          </div>
        </div>

        <div class="mainBlock__bottom">
          <div class="mainBlock__button-wr" v-if="type !== 'reset'">
            <button
              class="mainBlock__button"
              @click="$modal.show('LogInPopup')"
            >
              <span class="text">Log in</span>
            </button>
            <button
              class="mainBlock__button mainBlock__button--active"
              @click="$modal.show('SignUpPopup')"
            >
              <span class="text">Sign up</span>
            </button>
          </div>
<!--          <div class="mainBlock__fly">-->
<!--          <div class="mainBlock__fly-left">-->
<!--            <div class="mainBlock__flyGreen">-->
<!--              <div class="mainBlock__flyGreen-title" @click="$modal.show('MainFlyGreenPopup')">Fly<span class="mainBlock__flyGreen-color">Green</span>-->
<!--                <span class="mainBlock__flyGreen-arrow" v-if="!dMobile">-->
<!--                  <SvgIcon name="long-left-arrow"/>-->
<!--                </span>-->
<!--              </div>-->
<!--              <div class="mainBlock__flyGreen-link" @click="$modal.show('flycal')" v-if="dMobile">FLY<span class="mainBlock__flyGreen-color">Calendar</span>-->
<!--              </div>-->
<!--              <div class="mainBlock__flyGreen-title" style="margin-top: 10px" @click="$modal.show('flycal')" v-else>Fly<span class="mainBlock__flyGreen-color">Calendar</span>-->
<!--                <span class="mainBlock__flyGreen-arrow">-->
<!--                  <SvgIcon name="long-left-arrow"/>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="mainBlock__fly-right">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="mainBlock__flyGreen">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="mainBlock__flyGreen-title flyreport" @click="$router.push('/theflyreport')" v-if="dMobile">The&nbsp;<span class="mainBlock__flyGreen-greyColor">fly</span>&nbsp;report&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="mainBlock__flyGreen-title" @click="$router.push('/theflyreport')" v-else>The&nbsp;<span class="mainBlock__flyGreen-color">fly report</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="mainBlock__flyGreen-arrow" style="width: 19px">&ndash;&gt;-->
<!--&lt;!&ndash;                  <SvgIcon name="long-left-arrow"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="mainBlock__flyGreen-comment" v-if="!dMobile">Latest aviation and world news.</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
          <div class="mainBlock__flyGreen">
            <span style="display:flex; flex-direction:column; align-items:center;">
            <div class="mainBlock__flyGreen-title" @click="$modal.show('MainFlyGreenPopup')">Fly<span class="mainBlock__flyGreen-color">Green</span></div>
            </span>
            <span>
            <div class="mainBlock__flyGreen-link" @click="$modal.show('flycal')">FLYCalendar</div>
              <!-- <div class="mainBlock__info-text_sm">Check routes immediately</div> -->
            </span>
          </div>
          <a href="https://news.flyjets.com" class="mainBlock__flyReport-link">
            <div class="mainBlock__flyReport-link-left">
              <p class="mainBlock__flyReport-link-left-title">The Fly Report</p>
            </div>
            <SvgIcon name="right-arrow-2" />
          </a>
        </div>
<!--        <div class="mainBlock__contacts">-->
<!--          <div class="mainBlock__contacts-item">-->
<!--            <div class="mainBlock__contacts-label">CALL:</div>-->
<!--            <a class="mainBlock__contacts-link" href="tel:+12128455137">+1 (212) 845-5137</a>-->
<!--          </div>-->
<!--          <div class="mainBlock__contacts-item">-->
<!--            <div class="mainBlock__contacts-label">EMAIL:</div>-->
<!--            <a class="mainBlock__contacts-link" href="mailto:FLY@flyjets.com">FLY@flyjets.com</a>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <ThanksPopup />
    <!-- ----------------------------------------------------------------- -->
    <FlyCallPopup @payload="getPayload" />
    <LoginOrSignupPopup @authType="getAuthType" />
    <FlyCalLoginPopup @credentials="getCredentials" />
    <FlyCalSignUpPopup @otp="getOTP" @error="handleError" />
    <OneTimePasswordPopUp @verified="getVerified" />
    <InitiateTransactionPopUp @continue="getPayment" />
    <CompleteTransactionPopUp @success="onSuccess" @error="onError" />
    <InfoPopup/>
  </div>
</template>

<script>
  import './mainBlock.styl'
  import { usersAPI } from '@/api/accounts'
  import { bookingAPI } from '@/api/booking'
  // import SelectLang from '@/components/common/SelectLang/SelectLang'
  import ResetPassword from '@/components/ResetPassword/ResetPassword'
  import ThanksPopup from '@/components/common/modals/ThanksPopup';
  //
  import FlyCallPopup from '@/components/common/modals/FlyCallPopup'
  import LoginOrSignupPopup from '@/components/common/modals/LoginOrSignupPopup'
  import FlyCalLoginPopup from '@/components/common/modals/FlyCalLoginPopup'
  import FlyCalSignUpPopup from '@/components/common/modals/FlyCalSignupPopup'
  import OneTimePasswordPopUp from '@/components/common/modals/OneTimePasswordPopUp'
  import InitiateTransactionPopUp from '@/components/common/modals/InitiateTransactionPopUp'
  import CompleteTransactionPopUp from '@/components/common/modals/CompleteTransactionPopUp'
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';

  export default {
    props: ['type'],
    name: 'mainBlock',
    data: () => ({
      flyCalPayload: null,
      credentials: null,
      price: 0,
      dMobile: undefined,
    }),
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
      window.location.search.includes('login') ? this.$modal.show('LogInPopup') : window.location.search.includes('signup') && this.$modal.show('SignUpPopup');
    },
    methods: {
      getPayload (payload) {
        this.$modal.hide('flycal')

        // console.log(payload)
        this.flyCalPayload = payload

        this.$modal.show('login-or-signup')
      },
      getAuthType (authType) {
        this.$modal.hide('login-or-signup')

        if (authType === 'login') {
          this.$modal.show('flycal-login')
        } else {
          this.$modal.show('flycal-signup')
        }
      },
      async getCredentials (credentials) {
        try {
          const { data } = await usersAPI.loginUser(credentials)

          this.flyCalPayload.accountId = data.id

            if (data.isFlyCalMember) {
              await bookingAPI.flycalCreate(this.flyCalPayload)

              this.$modal.hide('flycal-login')

              const mainBlock = document.querySelector('.mainBlock')
              const logo = document.querySelector('.js-mainLogo')
              const helloUser = document.querySelector('.js-helloUser')
              const buttons = document.querySelector('.mainBlock__button-wr')

              if (mainBlock && logo && helloUser && buttons) {
                helloUser.classList.add('helloUser--show')
                setTimeout(() => {
                  mainBlock.classList.add('mainBlock--hide')
                }, 2500)
                setTimeout(() => {
                  helloUser.classList.remove('helloUser--show')

                  this.$router.push('/map-search')
                }, 3200)
              }
            } else {
              this.$modal.hide('flycal-login');
              await bookingAPI.getFlycalPaymentInfo().then((res) => {
                this.price = res.data;
              });
              this.$modal.show('initiate', { transactionType: 'flycal', price: this.price })
            }
        } catch (e) {
          console.log(e);
        }
      },
      getOTP (credentials) {
        this.flyCalPayload.accountId = credentials.id
        this.credentials = { email: credentials.email, password: credentials.password }

        this.$modal.hide('flycal-signup')
        this.$modal.show('one-time-password', { email: credentials.email })
      },
      async getVerified () {
        const result = await this.$store.dispatch('loginUser', this.credentials)

        if (result) {
          this.$modal.hide('one-time-password');
          await bookingAPI.getFlycalPaymentInfo().then((res) => {
            this.price = res.data;
          });
          this.$modal.show('initiate', { transactionType: 'flycal', price: this.price });
        } else {
          this.handleError('one-time-password')
        }
      },
      getPayment () {
        this.$modal.hide('initiate')
        this.$modal.show('complete', { transactionType: 'flycal', price: this.price });
      },
      onError (error) {
        this.$modal.hide('complete')
        this.$modal.show('InfoPopup', {
          title: 'Error',
          text: error.message,
        });
        console.log('error')
      },
      async onSuccess () {
        this.$modal.hide('complete');
        await bookingAPI.flycalCreate(this.flyCalPayload);
        const message = {
          title: 'Thank you!',
          text: 'Request created.',
        }
        this.$modal.show('ThanksPopup', {message});
        const mainBlock = document.querySelector('.mainBlock')
        const logo = document.querySelector('.js-mainLogo')
        const helloUser = document.querySelector('.js-helloUser')
        const buttons = document.querySelector('.mainBlock__button-wr')

        if (mainBlock && logo && helloUser && buttons) {
          helloUser.classList.add('helloUser--show')
          setTimeout(() => {
            this.$modal.hide('ThanksPopup');
            mainBlock.classList.add('mainBlock--hide')
          }, 2500)
          setTimeout(() => {
            helloUser.classList.remove('helloUser--show')

            this.$router.push('/map-search')
          }, 3200)
        }
        // }

        // show thanks popup w/ message and route to flyer dashboard flycal requests
      },
      handleError (name) {
        console.log('Something went wrong.')

        this.$modal.hide(name);
      },
      // ---------------------------------------------------------------------
      showModal (item) {
        this.$emit('showModal')
      },
    },
    computed: {
      isLogin () {
        return this.$store.state.auth.login
      },
    },
    components: {
      // SelectLang,
      ResetPassword,
      ThanksPopup,
      FlyCallPopup,
      LoginOrSignupPopup,
      FlyCalLoginPopup,
      FlyCalSignUpPopup,
      OneTimePasswordPopUp,
      InitiateTransactionPopUp,
      CompleteTransactionPopUp,
      InfoPopup,
      SvgIcon,
    },
    metaInfo: {
      title: 'FLYJETS',
      meta: [{
         name: 'description', content: 'Find and FLY: Search for and book charter aircraft (jets and helicopters) via the FLYJETS aviation marketplace.  🗞️ Home of The FLY Report, a digital newspaper about aviation, space, eVTOLs, renewables and travel.  ♻️🛸#FLYGreen and benefit from our FLYRewards program.',
      }],
    },
  }
</script>
